/* Reset */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Variables */
:root {
  --primary-color: #2c3e50;
  --secondary-color: #ecf0f1;
  --accent-color: #3498db;
  --font-family: "Helvetica Neue", Arial, sans-serif;
}

/* Body */
body {
  font-family: var(--font-family);
  background-color: var(--secondary-color);
  color: var(--primary-color);
  line-height: 1.6;
  padding: 20px;
}

/* Game Container */
.game {
  max-width: 400px;
  margin: 0 auto;
}

/* Game Info */
.game-info {
  margin-bottom: 20px;
  text-align: center;
}

.game-info div {
  margin-bottom: 10px;
}

.game-info button {
  background-color: var(--accent-color);
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  margin: 5px;
}

.game-info button:hover {
  background-color: #2980b9;
}

/* Game Board */
.game-board {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
  margin: 0 auto;
  width: 100%;
}

.square {
  background: #fff;
  border: 2px solid var(--primary-color);
  font-size: 48px;
  font-weight: bold;
  width: 100%;
  aspect-ratio: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.square:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
}

.square:hover:not(:disabled) {
  background-color: #ecf0f1;
}

/* Responsive Design */
@media (max-width: 500px) {
  .square {
    font-size: 36px;
  }

  .game-info button {
    padding: 8px 16px;
    font-size: 14px;
  }
}

/* Intro Screen */
.game-setup {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}

.game-setup button,
.game-setup input[type="text"] {
  background-color: var(--accent-color);
  color: #fff;
  border: none;
  padding: 10px;
  font-size: 16px;
  margin: 5px;
  width: calc(100% - 20px);
}

.game-setup input[type="text"] {
  background-color: #fff;
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
}

.game-setup button:hover,
.game-setup input[type="submit"]:hover {
  background-color: #2980b9;
}

.hostname-info {
  text-align: center;
  margin-top: 20px;
  color: #7f8c8d;
}
